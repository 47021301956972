import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                <a href="index.html" className="navbar-brand">
                  <h1 className="m-0 text-white">Shrewdmind</h1>
                </a>
                <p className="mt-3 mb-4">
                  {/* Lorem diam sit erat dolor elitr et, diam lorem justo amet
                  clita stet eos sit. Elitr dolor duo lorem, elitr clita ipsum
                  sea. Diam amet erat lorem stet eos. Diam amet et kasd eos duo. */}
                </p>
                <form action="">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control border-white p-3"
                      placeholder="Your Email"
                    />
                    <button className="btn btn-dark">Sign Up</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2"></i>
                    <p className="mb-0">
                      D-298/A Street No. 11 Harsh Vihar Delhi 110093
                    </p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2"></i>
                    <p className="mb-0">support@shrewdmind.com</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2"></i>
                    <p className="mb-0">+91-8076877163<br/> +91-8059942293</p>
                  </div>
                  <div className="d-flex mt-4">
                    <Link
                      className="btn btn-primary btn-square me-2"
                      to="https://www.facebook.com/profile.php?id=100091501009495"
                      preventScrollReset={true}
                    >
                      <i className="fab fa-twitter fw-normal"></i>
                    </Link>
                    <Link
                      className="btn btn-primary btn-square me-2"
                      to="https://www.facebook.com/profile.php?id=100091501009495"
                      preventScrollReset={true}
                    >
                      <i className="fab fa-facebook-f fw-normal"></i>
                    </Link>
                    <Link
                      className="btn btn-primary btn-square me-2"
                      to="https://www.linkedin.com/company/92800135/admin/?feedType=following"
                      preventScrollReset={true}
                    >
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </Link>
                    <Link
                      className="btn btn-primary btn-square me-2"
                      to="https://www.instagram.com/Shrewdmind/"
                      preventScrollReset={true}
                    >
                      <i className="fab fa-instagram fw-normal"></i>
                    </Link>
                    <Link
                      className="btn btn-primary btn-square me-2"
                      to="https://www.youtube.com/channel/UCR5tTZzHlLWJJKZxQ37cWDg"
                      preventScrollReset={true}
                    >
                      <i className="fab fa-youtube fw-normal"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Home
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      About Us
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>Our
                      Services
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Meet The Team
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Latest Blog
                    </a>
                    <a className="text-light" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Contact Us
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Popular Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Home
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      About Us
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Our Services
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Meet The Team
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Latest Blog
                    </a>
                    <a className="text-light" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-white"
        style={{ background: "#061429" }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "75px" }}
              >
                <p className="mb-0">
                  &copy;
                  <Link to="/" className="text-white border-bottom">
                    Shrewdmind Pvt. Ltd
                  </Link>
                  . All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
