import React from "react";
import { Link } from "react-router-dom";

const NavBarr = () => {
  return (
    <div className="container-fluid position-relative p-0">
      <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
        {/* <img src="../img/Shrewdmindlogo.png" style={{height:'30px',width:'30px'}} /> */}
        <img src="../img/sm-transparent.png" className="m-1 Shrewdmindlogo"/>
        <Link to="/" className="navbar-brand p-0">
          <h1 className="m-0">ShrewdMind</h1>
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <Link to="/" className="nav-item nav-link">
              Home
            </Link>
            <Link to="/aboutus" className="nav-item nav-link">
              About
            </Link>
            <Link to="/service" className="nav-item nav-link">
              Services
            </Link>
            {/* <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Blog
                </a>
                <div className="dropdown-menu m-0">
                  <Link to="/blog" className="dropdown-item">
                    Blog Grid
                  </Link>
                  <Link to="/detail" className="dropdown-item">
                    Blog Detail
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Pages
                </a>
                <div className="dropdown-menu m-0">
                  <a href="price.html" className="dropdown-item">
                    Pricing Plan
                  </a>
                  <a href="feature.html" className="dropdown-item">
                    Our features
                  </a>
                  <a href="team.html" className="dropdown-item">
                    Team Members
                  </a>
                  <a href="testimonial.html" className="dropdown-item">
                    Testimonial
                  </a>
                  <a href="quote.html" className="dropdown-item">
                    Free Quote
                  </a>
                </div>
              </div> */}
            <Link to="/contact" className="nav-item nav-link">
              Contact
            </Link>
          </div>
          {/* <butaton type="button" className="btn text-primary ms-3" data-bs-toggle="modal" data-bs-target="#searchModal"><i className="fa fa-search"></i></butaton>
            <a href="https://htmlcodex.com/startup-company-website-template" className="btn btn-primary py-2 px-4 ms-3">Download Pro Version</a> */}
        </div>
      </nav>

    </div>
  );
};

export default NavBarr;
