import React from "react";
import { Link } from "react-router-dom";

const ToopBar = () => {
  return (
    <div className="container-fluid bg-dark px-5 d-none d-lg-block">
      <div className="row gx-0">
        <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
          <div
            className="d-inline-flex align-items-center"
            style={{ height: "45px" }}
          >
            <small className="me-3 text-light">
              <i className="fa fa-map-marker-alt me-2"></i>D-298/A Street No. 11
              Harsh Vihar Delhi 110093
            </small>
            <small className="me-3 text-light">
              <i className="fa fa-phone-alt me-2"></i>+91-8059942293,+91-8076877163
            </small>
            <small className="text-light">
              <i className="fa fa-envelope-open me-2"></i>support@shrewdmind.com
            </small>
          </div>
        </div>
        <div className="col-lg-4 text-center text-lg-end">
          <div
            className="d-inline-flex align-items-center"
            style={{ height: "45px" }}
          >
            <Link
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
              to="https://www.facebook.com/profile.php?id=100091501009495"
              preventScrollReset={true}
              target="_blank"
            >
              <i className="fab fa-twitter fw-normal"></i>
            </Link>
            <Link
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
              to="https://www.facebook.com/profile.php?id=100091501009495"
              preventScrollReset={true}
              target="_blank"
            >
              <i className="fab fa-facebook-f fw-normal"></i>
            </Link>
            <Link
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
              to="https://www.linkedin.com/company/92800135/admin/?feedType=following"
              preventScrollReset={true}
              target="_blank"
            >
              <i className="fab fa-linkedin-in fw-normal"></i>
            </Link>
            <Link
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
              to="https://www.instagram.com/shrewdmind/"
              preventScrollReset={true}
              target="_blank"
            >
              <i className="fab fa-instagram fw-normal"></i>
            </Link>
            <Link
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
              to="https://www.youtube.com/channel/UCR5tTZzHlLWJJKZxQ37cWDg"
              preventScrollReset={true}
              target="_blank"
            >
            <i className="fab fa-youtube fw-normal"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToopBar;
