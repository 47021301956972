import React from 'react'
import {Route, Routes } from 'react-router-dom'
import ToopBar from '../Component/ToopBar'
import AboutUs from '../Pages/AboutUs'
import Home from '../Pages/Home'
import Service from '../Pages/Service'
import Contact from '../Pages/Contact'
import NavBarr from '../Component/NavBar'
import Footer from '../Component/Footer'
import Team from '../Pages/Team'
import Blog from '../Pages/Blog'
import Details from '../Pages/Details'
import Quote from '../Pages/Quote'
import UnderMaintanance from '../Pages/UnderMaintanance'

function RouterPath() {
  return (
    <>
    <ToopBar />
    <NavBarr />
    {/* <FullScreen /> */}
    <Routes>
      <Route path='/' element={<Home />} />
      <Route exact path='/aboutus' element={<AboutUs />} />
      <Route exact path='/service' element={<Service />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/blog' element={<Blog />} />
      <Route path='/detail' element={<Details />} />
      <Route path='/quote' element={<Quote />} />
      <Route path='/undermaintanance' element={<UnderMaintanance />} />
    </Routes>
    <Team />
    <Footer />
    </>
  )
}

export default RouterPath