import React from "react";
import HomeSlider from "./HomeSliders";

const Home = () => {
  return (
    <div>
      <HomeSlider />
    </div>
  );
};

export default Home;
