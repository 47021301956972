import React from "react";

const Team = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Team Members</h5>
          <h1 className="mb-0">
            Professional Stuffs Ready to Help Your Business
          </h1>
        </div>
        <div className="row imagecenter ">
          {/* <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
            <div className="team-item bg-light rounded overflow-hidden">
              <div className="team-img position-relative overflow-hidden" style={{maxHeight:"10%"}}>
                <img className="img-fluid w-100" src="img/Abhishek.jpg" alt="" style={{maxHeight:"10%"}}/>
                <div className="team-social">
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-twitter fw-normal"></i>
                  </a>
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-facebook-f fw-normal"></i>
                  </a>
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-instagram fw-normal"></i>
                  </a>
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-linkedin-in fw-normal"></i>
                  </a>
                </div>
              </div>
              <div className="text-center py-4">
                <h4 className="text-primary">Abhishek Kumar</h4>
                <p className="text-uppercase m-0">Co-founder </p>
              </div>
            </div>
          </div> */}
          <div className="col-4" data-wow-delay="0.6s">
            <div className="team-item bg-light rounded overflow-hidden">
              <div className="team-img position-relative overflow-hidden">
                {/* <img className="img-fluid w-100" src="img/anish.jpeg" alt="" /> */}
                <div className="team-social ">
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-twitter fw-normal"></i>
                  </a>
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-facebook-f fw-normal"></i>
                  </a>
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-instagram fw-normal"></i>
                  </a>
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-linkedin-in fw-normal"></i>
                  </a>
                </div>
              </div>
              {/* <div className="text-center py-4">
                <h4 className="text-primary">Anish Gupta</h4>
                <p className="text-uppercase m-0">Chief Executive Officer (CEO) </p>
              </div> */}
            </div>
          </div>
          {/* <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
             <div className="team-item bg-light rounded overflow-hidden">
              <div className="team-img position-relative overflow-hidden">
                <img className="img-fluid w-100" src="img/lalit.jpeg" alt="" />
                <div className="team-social">
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-twitter fw-normal"></i>
                  </a>
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-facebook-f fw-normal"></i>
                  </a>
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-instagram fw-normal"></i>
                  </a>
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href=""
                  >
                    <i className="fab fa-linkedin-in fw-normal"></i>
                  </a>
                </div>
              </div>
              <div className="text-center py-4">
                <h4 className="text-primary">Lalit Gaur</h4>
                <p className="text-uppercase m-0">Co-founder</p>
              </div>
            </div>
          </div>  */}
        </div>
      </div>
    </div>
  );
};

export default Team;
