import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

const Quote = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData({ ...formData, [name]: value })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('http://www.shrewdmind.com/api/v1/lead/create', { name: formData.name, email: formData.email, message: formData.message })
      .then((res) => {
        console.log(res, 'success')
        Swal.fire({
          title: 'Successfully sumbited',
          text: 'We will contact you soon...',
          icon: 'success',
          showConfirmButton: false,
          timer:2000
        })

        // window.location.reload(false)
      })
      .catch((err) => {
        console.log(err, 'error')
      })

    setFormData({
      name: '',
      email: '',
      message: ''
    })
  }
  return (
    <>
      <div className="container-fluid bg-primary py-5 bg-header">
        <div className="row py-5">
          <div className="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 className="display-4 text-white animated zoomIn">Free Quote</h1>
            <a href="" className="h5 text-white">
              Home
            </a>
            <i className="far fa-circle text-white px-2"></i>
            <a href="" className="h5 text-white">
              Quotes
            </a>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  Request A Quote
                </h5>
                <h1 className="mb-0">
                  Need A Free Quote? Please Feel Free to Contact Us
                </h1>
              </div>
              <div className="row gx-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-4">
                    <i className="fa fa-reply text-primary me-3"></i>Reply
                    within 24 hours
                  </h5>
                </div>
                {/* <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                <h5 className="mb-4">
                  <i className="fa fa-phone-alt text-primary me-3"></i>24 hrs
                   telephone support
                </h5>
              </div> */}
              </div>
              {/* <p className="mb-4">
              Eirmod sed tempor lorem ut dolores. Aliquyam sit sadipscing kasd
              ipsum. Dolor ea et dolore et at sea ea at dolor, justo ipsum duo
              rebum sea invidunt voluptua. Eos vero eos vero ea et dolore eirmod
              et. Dolores diam duo invidunt lorem. Elitr ut dolores magna sit.
              Sea dolore sanctus sed et. Takimata takimata sanctus sed.
            </p> */}
              <div
                className="d-flex align-items-center mt-2 wow zoomIn"
                data-wow-delay="0.6s"
              >
                {/* <div
                className="bg-primary d-flex align-items-center justify-content-center rounded"
                style={{ width: "60px", height: "60px" }}
              >
                <i className="fa fa-phone-alt text-white"></i>
              </div> */}
                {/* <div className="ps-4">
                <h5 className="mb-2">Call to ask any question</h5>
                <h4 className="text-primary mb-0">+012 345 6789</h4>
              </div> */}
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
                data-wow-delay="0.9s"
              >
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-xl-12">
                      <input
                        type="text"
                        name="name"
                        required
                        value={formData.name}
                        onChange={handleInputChange}
                        className="form-control bg-light border-0"
                        placeholder="Your Name"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="email"
                        name="email"
                        required
                        value={formData.email}
                        onChange={handleInputChange}
                        className="form-control bg-light border-0"
                        placeholder="Your Email"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control bg-light border-0"
                        rows="3"
                        name="message"
                        required
                        onChange={handleInputChange}
                        value={formData.message}
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-dark w-100 py-3" type="submit" > Request A Quote </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quote;
